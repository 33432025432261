// src/App.js
import React, {Suspense, useEffect, useState} from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
const Patent = React.lazy(() => import('./components/Patent.jsx'));
const Register = React.lazy(() => import('./components//Register'));

const App = () => {

    return (
        <Router>
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route path="/*" element={<ModeComponent />} />
                </Routes>
            </Suspense>
        </Router>
    );
};

const ModeComponent = () => {
    const search = useLocation().search;
    const location = useLocation();
    const params = new URLSearchParams(search);
    const [mode, setMode] = useState(params.get('mode') || 'Patent');

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const currentMode = params.get('mode') || 'Patent';
        setMode(currentMode);
    }, [location.search]);

    if (mode === 'Patent') {
        return <Patent setMode={setMode}/>;
    } else if (mode === 'Register') {
        return <Register setMode={setMode}/>;
    } else {
        return <div>Invalid mode</div>;
    }
};

export default App;
